import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../../components/Layout"
import SEO from "../../components/seo"

import * as S from "../../components/Pages/general"

const ColorindoFanArtPage = () => {
  const { colorindoFanArtImg } = useStaticQuery(graphql`
    query {
      colorindoFanArtImg: file(relativePath: { eq: "colorindo-fanart.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO
        title="Colorindo Fan Art - Combo 2 em 1"
        description="Nesse combo incrível você vai adquirir 2 cursos pelo preço de 1, sendo o próximo passo ideal para se aprimorar, aprendendo a colorir seus desenhos e criar seus próprios personagens."
        image="/img/colorindo-fanart.jpg"
      />
      <S.GeneralWrapper>
        <S.GeneralTitle>Colorindo Fan Art - Combo 2 em 1</S.GeneralTitle>
        <S.GeneralHighlight>
          Aprenda a dar mais vida aos seus desenhos colorindo seus Fan Art e de
          bônus leva um curso para conseguir dar vida a sua imaginação e criar
          seus próprios personagens.
        </S.GeneralHighlight>
        <S.GeneralSection>
          <S.GeneralBox shadow>
            <S.GeneralExternalLink
              href="http://bit.ly/aprendendo-desenhar-colorindo-fan-art"
              target="_blank"
            >
              <Img
                fluid={colorindoFanArtImg.childImageSharp.fluid}
                alt="Imagem com o título de combo ultimate e vários personagens de anime ao funto"
              />
            </S.GeneralExternalLink>
          </S.GeneralBox>
          <S.GeneralBox shadow>
            <S.GeneralSubTitle>Combo 2 em 1</S.GeneralSubTitle>
            <S.GeneralStrongText>
              Isso é o que você vai receber ao garantir o Combo Colorindo Fan
              Art
            </S.GeneralStrongText>
            <S.GeneralList>
              <S.GeneralListItem>- Curso Colorindo Fan Art</S.GeneralListItem>
              <S.GeneralListItem>
                - Como Criar Seus Próprios Personagens
              </S.GeneralListItem>
              <S.GeneralListItem>
                - Acesso Vitalício ao Material
              </S.GeneralListItem>
              <S.GeneralListItem>- 7 Dia de Garantia Total</S.GeneralListItem>
            </S.GeneralList>
          </S.GeneralBox>
        </S.GeneralSection>
        <S.GeneralFinalCall
          href="http://bit.ly/aprendendo-desenhar-colorindo-fan-art"
          target="_blank"
        >
          OBTENHA TODOS OS DETALHES GARANTA SEU DESCONTO AGORA!!!
        </S.GeneralFinalCall>
      </S.GeneralWrapper>
    </Layout>
  )
}

export default ColorindoFanArtPage
